import { yupResolver } from '@hookform/resolvers/yup';
import { HeadFC } from 'gatsby';
import { FC, useState } from 'react';
import * as React from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import eyeHidden from '../../assets/eye_hidden.svg';
import eyeVisible from '../../assets/eye_visible.svg';
import padlock from '../../assets/padlock.svg';
import { Button } from '../../components/Button';
import { ErrorAlert } from '../../components/ErrorAlert';
import { Caption2 } from '../../components/Fonts/Caption';
import { Footnote } from '../../components/Fonts/Footnote';
import { H5 } from '../../components/Fonts/HTags';
import Footer from '../../components/Footer';
import { TextField } from '../../components/Input';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';
import { sentryCaptureException } from '../../services/sentry';

declare global {
  interface Window {
    ReactNativeWebView: any; // this will be your variable name
  }
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required('La contraseña es requerida.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[¿.,:/'+"¡?!@#\-_=$%^&*])(?=.{8,})/,
      'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un carácter especial',
    ),
  password_confirmation: yup
    .string()
    .required('Confirmar la contraseña es requerido.')
    .oneOf([yup.ref('password'), null], 'La contraseña debe coincidir.'),
});

const FormStyled = styled.form`
  width: 97%;
`;

const EyeImg = styled.img`
  width: 30px;
  height: auto;
  position: absolute;
  right: 0;
  top: 12px;
`;

const ContainerPass = styled.div`
  width: 99%;
  position: relative;
`;

const Padlock = styled(Footnote)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 15px;

  img {
    margin-right: 13px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LegalText = styled.span`
  color: ${({ theme }) => theme.colors.mainAguaColor};
`;

const PasswordInput: FC<{
  name: string;
  label: string;
  control: any;
  messageError: FieldError | undefined;
}> = ({ name, label, control, messageError }) => {
  const [hidden, setHidden] = useState(false);
  return (
    <ContainerPass>
      {hidden && <EyeImg onClick={() => setHidden(false)} src={eyeHidden} alt="eyeHidden" />}
      {!hidden && <EyeImg onClick={() => setHidden(true)} src={eyeVisible} alt="eyeVisible" />}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={!hidden ? 'password' : 'text'}
            label={label}
            messageError={messageError}
          />
        )}
      />
    </ContainerPass>
  );
};

const ResetPassword: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const params = new URLSearchParams(location?.search);
    const newToken = params?.get('token');
    if (newToken) {
      setToken(newToken);
    }
  }, []);

  React.useEffect(() => {
    if (token) {
      validateToken();
    }
  }, [token]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema),
  });

  const validateToken = async () => {
    try {
      setIsLoading(true);
      const response = await server.post(`/api/v1/users/validate_password_reset`, {
        token,
      });
      const { token: newToken } = response.data;
      console.log('setting new token', newToken);

      setResetPasswordToken(newToken);

      setIsLoading(false);
      setErrorMessage('');
    } catch (error) {
      sentryCaptureException(error);
      setErrorMessage(
        'Algo salió mal y no hemos podido validar su identidad, regrese a la APP Carpediem para reiniciar el proceso.',
      );
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await server.post(`/api/v1/users/reset_password`, {
        password: data.password,
        token: resetPasswordToken,
      });
      setIsDone(true);
      setErrorMessage('');
    } catch {
      setErrorMessage(
        'Algo salió mal y no hemos podido validar su identidad, regrese a la APP Carpediem para reiniciar el proceso.',
      );
      setIsDone(false);
      setIsLoading(false);
    }
  };

  const { password, password_confirmation } = errors;

  if (isLoading)
    return (
      <Loading
        mainTitle={
          isDone
            ? '¡Genial! tu cuenta ha sido recuperada, ahora abre la APP Carpediem.'
            : 'Por favor espere un momento...'
        }
        // description="Ac ac orci, nulla sit interdum. Sapien nisl vulputate at morbi eget pharetra urna. Tellus nec eu egestas sagittis."
        type={isDone ? 'done' : 'loading'}
      />
    );

  return (
    <Base>
      <NavBar>
        <InfoContainer>
          <H5 color="simpleWhite" fontWeight={fontWeightType.Bold}>
            Recupera tu cuenta
          </H5>
        </InfoContainer>
        <Spacer size="12" />
      </NavBar>
      <View>
        <Spacer size="30" />
        {errorMessage ? <ErrorAlert message={errorMessage} /> : null}
        <H5 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Recupera tu cuenta en Carpediem
        </H5>
        <Spacer size="18" />
        <Padlock fontWeight={fontWeightType.SemiBold}>
          <img src={padlock} alt="padlock" />
          Para recuperar tu cuenta ingresa una contraseña nueva.
        </Padlock>
        <Spacer size="30" />
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            name="password"
            label="Nueva Contraseña"
            control={control}
            messageError={password}
          />
          <Spacer size="30" />
          <PasswordInput
            name="password_confirmation"
            label="Confirmar Nueva Contraseña"
            control={control}
            messageError={password_confirmation}
          />
          <Spacer size="30" />
          <Caption2 color="mainGray" fontWeight={fontWeightType.Bold}>
            Al dar click en “Continuar” usted acepta nuestros{' '}
            <LegalText>Términos y Condiciones.</LegalText>
          </Caption2>
          <Spacer size="30" />
          <Button type="submit">Cambiar Contraseña</Button>
        </FormStyled>
      </View>
      <Spacer size="30" />
      <Footer />
    </Base>
  );
};

export default ResetPassword;

export const Head: HeadFC = () => {
  return (
    <>
      <title>Reset Password</title>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </>
  );
};
